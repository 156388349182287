import API from './index';

export const getPosts = ({
  page,
  type,
  categoriesIds,
  typeContent,
  search,
  forLoad,
  companyId,
  userId,
  tags
}) =>
  API.get(`public/posts`, {
    params: {
      page,
      type,
      type_content: typeContent,
      categories: categoriesIds,
      search,
      forLoad,
      companyId,
      userId,
      tags
    }
  });
export const getMainBanners = () =>
  API.get(`public/main_banners`, {
    params: {}
  });
