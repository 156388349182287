import * as ROUTES from '../router/routes';

export function parseUrl(url) {
  const list = [
    {
      reg: /\/company\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterCompany().name,
          params: {
            school_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/category\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterPostsCategory().name,
          params: {
            category_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/video\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routeCenterVideo().name,
          params: {
            post_id: match[1]
          }
        };
      }
    },
    {
      reg: /\/post\/(.+)$/,
      getRoute: (match) => {
        return {
          name: ROUTES.routePostByArticle().name,
          params: {
            post_id: match[1]
          }
        };
      }
    }
  ];
  for (let i in list) {
    let row = list[i];
    if (row.reg.test(url)) {
      const match = url.match(row.reg);
      return {
        match,
        route: row.getRoute(match),
        ...row
      };
    }
  }
  return null;
}
